import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.scss";

const URL = "https://www.walking-tour.com";

const Logo = () => {
  return (
    <div className="logo">
      <a href={URL} alt="Walking Tour HomePage">
        <picture>
          <source srcSet="/logo.webp" type="image/webp" />
          <img src="/logo.png" alt="walking tour logo" />
        </picture>
      </a>
    </div>
  );
};

const RedirectButton = () => {
  let { pathname } = useLocation();
  const name = pathname.includes("Password")
    ? "Reset Password"
    : "Confirm Email adress";
  return (
    <>
      <h1>{name}</h1>
      <a className="button" href={`walking-tour:/${pathname.toString()}`}>
        Open in App
      </a>
      <p>Sorry, we don't support {name} in Browser yet</p>
    </>
  );
};

const Redirect = () => {
  document.location.href = URL;
  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Logo />
        <div className="main">
          <Switch>
            <Route path="/confirm/:token">
              <RedirectButton />
            </Route>
            <Route path="/resetPassword/:token">
              <RedirectButton />
            </Route>
            <Route path="/">
              <Redirect />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
